img.logo {
  width: 90px;
}
.main {
  padding: 40px 0px;
}
footer {
  background: #922793;
  padding: 30px;
  color:#fff;
}
.main-area {
  min-height: 400px;
}
.image-prod {
  width: 100%;
  height: 250px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.list-photos {
  padding: 50px 0px;
}
.pic-box {
  background: #f7f7f7;
  padding: 10px;
  margin-bottom: 30px;
  border-radius: 11px;
}
h4.tt-bx {
  font-weight: 100;
  height: 44px;
  text-align: center;
  font-size: 18px;
}

.btn-purple{
  color: #fff;
  background-color: #92278f;
  border-color: #92278f;
  border-radius: 2px;
}
.btn-full {
  width: 100%;
}
.mainimage-flap {
  height: 500px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.lbl-titles {
  font-weight: 700;
  margin-top: 15px;
}
.btn-purple:hover {
  background-color: #fff;
  color: #92278f;
  border-color: #92278f;
  border-radius: 2px;
}